.reminderDisclaimerText {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  flex-grow: 1;
}
/* color.PRIMARY_BRAND */
.anchorText {
  text-decoration: none;
  color: #0077c8;
  font-weight: 600;
}
.anchorTextInNewLine {
  display: flex;
  justify-content: flex-end;
}
